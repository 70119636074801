@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

html {
  font-size: 62.5%;
  /* 62.5% of 16px = 10px */
}

.input-textfield {
  border-radius: 8.855px;
  background: #0031621F;
  width: 100%;
  font-weight: 500;
  font-size: 1.4rem;
  border: none;
  outline: none;
  border-radius: .5rem;
  min-height: 4rem;
  color: 'red';
  padding: 1.4rem 1.4rem;
}

::-webkit-input-placeholder {
  color: '#003162';
}
::-webkit-scrollbar{
  width: 8px;
  background-color:transparent
  ;
}

::-webkit-scrollbar-thumb{
  background-color:#EFF5FF  ;
  border-radius: 10px;

}